import { ImageObject } from '@utils/types';
import classNames from 'classnames';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const Tile = ({
	title,
	children,
	image,
	link,
	as: Component = 'article',
	className = '',
	type = 'default',
}: {
	title: string;
	children: React.ReactNode;
	image?: ImageObject;
	link: string;
	as?: React.ElementType;
	className?: string;
	type?: 'default' | 'standard' | 'plain' | 'icon';
}) => {
	const gatsbyImage = image && getImage(image.src);

	return (
		<Component
			className={classNames('tile sector--software', {
				[`tile--${type}`]: !!type,
				[className]: !!className,
			})}>
			{gatsbyImage && (
				<div
					className={classNames('tile__bkg', {
						'image-contain': type === 'plain' || type === 'icon',
						'image-background':
							type === 'standard' || type === 'default',
					})}>
					<GatsbyImage
						image={gatsbyImage}
						alt={image.alt}
						objectFit={
							type === 'plain' || type === 'icon'
								? 'contain'
								: 'cover'
						}
					/>
				</div>
			)}
			<div className="tile__content">
				<h3 className="tile__title">{children}</h3>
			</div>

			{link && (
				<Link to={link} className="tile__link link-coverer">
					{`Read more about ${title}`}
				</Link>
			)}
		</Component>
	);
};

export default Tile;
